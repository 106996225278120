/**
 * Inspiration database.
 */
import { apiGet } from '@/util/api';
import Icon from '@/icons/Icon.vue';
import ContentList from '@/components/ContentList.vue';

export default {
  name: 'Inspiration',
  components: {
    Icon,
    ContentList,
  },
  props: {
    category: { type: String, default: '' },
    genre: { type: String, default: '' },
    country: { type: String, default: '' },
  },
  data: () => ({
    loading: true,
    contents: [],

    countries: [],
    genres: [],
    showBookmarked: false,

    // Pagination configs
    currentPage: 1,
    perPage: 12,
    rows: 0,
  }),
  computed: {
    from: function() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    to: function() {
      return this.rows / this.perPage < this.currentPage
        ? this.rows
        : this.currentPage * this.perPage;
    },
  },
  async created() {
    if (!this.category) {
      await this.route('beautiful');
    }

    await this.getGenres();
    await this.getCountries();
    await this.getContents();
  },
  watch: {
    currentPage: async function() {
      await this.getContents();
    },
    category: async function() {
      this.currentPage = 1;
      await this.getGenres();
      await this.getContents();
    },
    genre: async function() {
      this.currentPage = 1;
      await this.getContents();
    },
    country: async function() {
      this.currentPage = 1;
      await this.getContents();
    },
    showBookmarked: async function() {
      this.currentPage = 1;
      await this.getContents();
    },
  },
  methods: {
    /**
     * Get the list of countries which the user has access to, for loading into the content filter.
     */
    async getCountries() {
      this.loading = true;
      const countries = await apiGet('/tag/countries');
      this.countries = countries.sort((c1, c2) => {
        return c1.name === c2.name ? 0 : c1.name < c2.name ? -1 : 1;
      });
      this.loading = false;
    },
    /**
     * Get the list of genres in the current category, to load into the content filter.
     */
    async getGenres() {
      this.loading = true;
      if (!this.category) return null;
      this.genres = await apiGet(
        `/tag?category=GENRE&tagCategory=${this.category}&orderBySort=name&orderByOrder=ASC`,
      );
      this.loading = false;
    },
    /**
     * Get the content items which correspond to the current set of filters.
     */
    async getContents() {
      this.loading = true;
      try {
        let query = `?skip=${this.perPage * (this.currentPage - 1)}&take=${
          this.perPage
        }&orderBySort=created&orderByOrder=DESC&category=${
          this.category
        }&bookmarked=${this.showBookmarked}`;

        if (this.genre) {
          query += `&genre=${this.genre}`;
        }

        if (this.country) {
          query += `&country=${this.country}`;
        }

        this.contents = (await apiGet(`/content${query}`)).map(content => {
          const cdate = content.created
            .substr(0, content.created.indexOf('T'))
            .split('-');
          content.created = `${cdate[2]}/${cdate[1]}/${cdate[0]}`;
          content.showBookmark = true;
          return content;
        });
        this.rows = await apiGet(`/content/count${query}`);
      } catch (e) {
        this.$bvToast.toast(this.$t('inspiration_page_error_get_content'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      } finally {
        this.loading = false;
      }
    },
    async route(category, genre, country) {
      this.loading = true;
      if (category) category = category.toLowerCase();
      if (genre) genre = genre.toLowerCase();
      if (country) country = country.toLowerCase();
      if (
        category !== this.category ||
        genre !== this.genre ||
        country !== this.country
      ) {
        const pushParams = {};

        if (category) pushParams.category = category;
        if (genre) pushParams.genre = genre;
        if (country) pushParams.country = country;

        await this.$router.push({ query: pushParams });
      } else {
        this.loading = false;
      }
    },
  },
};
